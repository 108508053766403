<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3
                        v-if="isNewUser"
                        class="kt-portlet__head-title"
                    >
                        Add New User
                    </h3>
                    <h3
                        v-else
                        class="kt-portlet__head-title"
                    >
                        Edit User - {{ staffUser.schoolEmail }}
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-group">
                        <a
                            href="#"
                            class="btn btn-sm btn-icon btn-clean btn-icon-md"
                            @click.stop.prevent="exit"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__body">
                <div class="form-group validated row pl-2 pr-2">
                    <div class="col-6">
                        First Name:
                        <input
                            v-model="staffUser.firstName"
                            type="text"
                            class="form-control"
                            :class="isFirstNameValid ? '' : 'is-invalid'"
                        >
                        <div
                            v-if="!isFirstNameValid"
                            class="invalid-feedback"
                        >
                            Please enter a first name
                        </div>
                    </div>
                    <div class="col-6">
                        Last Name:
                        <input
                            v-model="staffUser.lastName"
                            type="text"
                            class="form-control"
                            :class="isLastNameValid ? '' : 'is-invalid'"
                        >
                        <div
                            v-if="!isLastNameValid"
                            class="invalid-feedback"
                        >
                            Please enter a last name
                        </div>
                    </div>
                </div>
                <div class="form-group validated row pl-2 pr-2">
                    <div class="col-6">
                        Job Title:
                        <input
                            v-model="staffUser.jobTitle"
                            type="text"
                            class="form-control"
                            :class="isJobTitleValid ? '' : 'is-invalid'"
                        >
                        <div
                            v-if="!isJobTitleValid"
                            class="invalid-feedback"
                        >
                            Please enter a job title.
                        </div>
                        <span class="form-text text-muted">
                            This field is free-formed and provided for your use.
                        </span>
                    </div>
                    <div class="col-6">
                        {{ user.school.name }} Role:
                        <select
                            v-model="staffUser.roleId"
                            class="form-control"
                        >
                            <option
                                v-for="role in roles"
                                :key="`role_${role.roleId}_${staffUser.userId}`"
                                :value="role.roleId"
                                :selected="role.roleId == staffUser.roleId"
                            >
                                {{ role.name }}
                            </option>
                        </select>
                        <span
                            v-if="(staffUser.roleId == 4)"
                            class="form-text text-muted"
                        >
                            School Admins have access to unspecified permissions, such as school settings pages.
                        </span>

                        <span
                            v-if="(staffUser.roleId == 7)"
                            class="form-text text-muted"
                        >
                            Staff members only have access to the permissions checked below, unless they are teaching
                            courses, in which case the user will be treated as a teacher.
                        </span>
                        <span
                            v-if="(staffUser.roleId == 6)"
                            class="form-text text-muted"
                        >
                            Teachers always have access to students within their courses as supplied by the SIS.
                            Additional access permissions can be granted below.
                        </span>
                    </div>
                </div>
                <div class="form-group validated row pl-2 pr-2">
                    <div class="col-6">
                        School Email Address:
                        <input
                            v-model="staffUser.schoolEmail"
                            :placeholder="`e.g. barack.obama@${mySchoolDomain}`"
                            type="email"
                            :class="isValidSchoolDomain ? '' : 'is-invalid-warn'"
                            :disabled="!user.isSuperAdmin && !isNewUser"
                            class="form-control"
                        >
                        <div
                            v-if="!isValidSchoolDomain"
                            class="invalid-warn-feedback"
                        >
                            Please enter a valid email address ending in <code>@{{ mySchoolDomain }}</code>
                            <span v-if="mySchoolDomain !== nycDomain">
                                or <code>@{{ nycDomain }}</code>
                            </span>
                        </div>
                        <span class="form-text text-muted">
                            This field should not be edited. Editing may disable this user's authentication.
                        </span>
                    </div>
                    <div
                        v-if="!$_userMixins_isGoogleDisabled"
                        class="col-6"
                    >
                        Google Email Address:
                        <input
                            v-model="staffUser.googleEmail"
                            :class="isValidGoogleDomain ? '' : 'is-invalid-warn'"
                            :placeholder="`e.g. barack.obama@${myGoogleDomain}`"
                            type="email"
                            class="form-control"
                        >
                        <div
                            v-if="!isValidGoogleDomain"
                            class="invalid-warn-feedback"
                        >
                            Please enter a valid email address ending in <code>@{{ myGoogleDomain }}</code>
                        </div>
                    </div>
                </div>

                <div class="kt-section__body col-lg-12 pt-4">
                    <div class="form-group row pb-4 pl-4 pr-4 sg-bordered-row">
                        <span class="sg-border-label">Permissions</span>
                        <div class="col-12 pt-3" />

                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="Can Create/Delete Courses and manage rostering"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.canManageCourses"
                                    type="checkbox"
                                >
                                Can manage courses and rostering
                                <span />
                            </label>
                        </div>
                        <div class="col-4 pb-2 pl-1">
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.accessAllStudentPortfolios"
                                    type="checkbox"
                                >
                                Allow access to all Student Portfolios
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="Read only access to all teacher portfolio pages"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.accessAllTeacherPortfolios"
                                    type="checkbox"
                                >
                                Allow access to all Teacher Portfolios
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="This allows a user to see all non-confidential anecdotals created in this school.
                            The user may not have permission to access all anecdotal participants, if porfolio access is not also granted."
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.accessAllAnecdotalEvents"
                                    type="checkbox"
                                >
                                Allow access to all Anecdotal Events
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="This user can edit any and all anecdotals they have permission to view"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.editSchoolAnecdotalEvents"
                                    type="checkbox"
                                >
                                Can edit all anecdotals events in the school
                                <span />
                            </label>
                        </div>

                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="If this user teaches courses, then they can choose a grade template from school templates"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.canChooseGradeTemplate"
                                    type="checkbox"
                                >
                                Can choose from available grade templates for courses taught.
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="This user can manage purchase orders."
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.canManagePurchaseOrders"
                                    type="checkbox"
                                >
                                Can manage purchase orders.
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="Can create and submit Bug Reports to SyncGrades"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.canSubmitBugReports"
                                    type="checkbox"
                                >
                                Can submit bug reports
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="Can create and submit Bug Reports to SyncGrades"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.canMessageSchoolUsers"
                                    type="checkbox"
                                >
                                Can send communications to school users
                                <span />
                            </label>
                        </div>
                        <div
                            v-b-tooltip.hover
                            class="col-4 pb-2 pl-1"
                            title="Can create and submit Bug Reports to SyncGrades"
                        >
                            <label class="kt-checkbox kt-checkbox--success">
                                <input
                                    v-model="staffUser.userPermissions.canMessageHomeUsers"
                                    type="checkbox"
                                >
                                Can send communications to home users
                                <span />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot">
                <div class="kt-form__actions">
                    <button
                        v-if="!isNewUser"
                        type="button"
                        class="btn btn-danger"
                        data-dismiss="modal"
                        :disabled="!isFormValid"
                        @click.stop.prevent="deleteUser()"
                    >
                        <i class="fa fa-trash" />
                        Delete User
                    </button>

                    <button
                        type="button"
                        class="btn btn-primary alignment pull-right"
                        data-dismiss="modal"
                        :disabled="!isFormValid"
                        :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                        @click.stop.prevent="saveUser()"
                    >
                        <i
                            v-if="!saving"
                            class="fa fa-save"
                        />
                        {{ saving ? "Saving..." : "Save User" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import * as network from '../../network';
import Types from '../../store/Types';
import userMixins from '../../store/mixins/userMixins';

export default Vue.extend({
    name: 'UserEditOrCreate',
    mixins: [userMixins],
    data() {
        return {
            nycDomain: 'schools.nyc.gov',
            saving: false,
            isNewUser: false,
            staffUser: {
                userId: 0,
                schoolEmail: null,
                googleEmail: null,
                firstName: null,
                lastName: null,
                jobTitle: null,
                roleId: '4',
                userPermissions: {
                    canManageCourses: false,
                    accessAllStudentPortfolios: false,
                    accessAllTeacherPortfolios: false,
                    accessAllAnecdotalEvents: false,
                    editSchoolAnecdotalEvents: false,
                    canChooseGradeTemplate: false,
                    canManagePurchaseOrders: false,
                    canSubmitBugReports: false,
                    canMessageSchoolUsers: false,
                    canMessageHomeUsers: false,
                },
            },
            roles: [{
                roleId: 4,
                name: 'School Admin',
            }, {
                roleId: 7,
                name: 'Staff',
            }, {
                roleId: 6,
                name: 'Teacher',
            }],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            userName: (state) => state.user.mappedUserName || state.user.userName,
            staffUsers: (state) => state.database.users,
        }),
        newUserObject() {
            const { staffUser } = this;
            const output = { ...staffUser };
            output.deleted = false;
            delete output.userId;
            return output;
        },
        editUserObject() {
            const { staffUser } = this;
            const output = { ...staffUser };
            output.deleted = false;
            return output;
        },
        thisSchoolDomain() {
            if (!this.isValidSchoolEmail) return null;
            return this.staffUser.schoolEmail.split('@')[1].toLowerCase();
        },
        thisGoogleDomain() {
            if (!this.isValidGoogleEmail) return null;
            return this.staffUser.googleEmail.split('@')[1].toLowerCase();
        },
        mySchoolDomain() {
            if (!this.userName) return null;
            const domain = this.userName.split('@')[1].toLowerCase();
            return domain;
        },
        myGoogleDomain() {
            if (!this.user.googleEmail) return null;
            return this.user.googleEmail.split('@')[1].toLowerCase();
        },
        isValidSchoolEmail() {
            let { schoolEmail } = this.staffUser;
            schoolEmail = schoolEmail || '';
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(schoolEmail.toLowerCase());
        },
        isValidGoogleEmail() {
            if (this.$_userMixins_isGoogleDisabled) return true;
            let { googleEmail } = this.staffUser;
            googleEmail = googleEmail || '';
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(googleEmail.toLowerCase());
        },
        isValidSchoolDomain() {
            if (!this.isValidSchoolEmail) return false;
            return this.mySchoolDomain == this.thisSchoolDomain || this.nycDomain == this.thisSchoolDomain;
        },
        isValidGoogleDomain() {
            if (!this.isValidGoogleEmail) return false;
            return this.myGoogleDomain == this.thisGoogleDomain;
        },
        isFirstNameValid() {
            return (this.staffUser.firstName || '').length > 0;
        },
        isLastNameValid() {
            return (this.staffUser.lastName || '').length > 0;
        },
        isJobTitleValid() {
            return (this.staffUser.jobTitle || '').length > 0;
        },
        isRoleValid() {
            return Boolean(this.staffUser.roleId);
        },
        isFormValid() {
            if (!this.isValidGoogleEmail) return false;
            if (!this.isValidSchoolEmail) return false;
            if (!this.isFirstNameValid) return false;
            if (!this.isLastNameValid) return false;
            if (!this.isJobTitleValid) return false;
            if (!this.isRoleValid) return false;
            return true;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        exit() {
            this.$router.push({ name: 'ManageUsers' });
        },
        populate() {
            const { schoolEmail } = this.$route.params;
            const staffUser = this.staffUsers.find((u) => u.schoolEmail == schoolEmail);

            if (!staffUser) {
                this.isNewUser = true;
                return;
            }

            this.isNewUser = false;

            if (!staffUser.userPermissions) { // because sub-properties dont spread
                staffUser.userPermissions = this.staffUser.userPermissions;
            }

            // apply user to local data
            this.staffUser = {
                ...this.staffUser,
                ...staffUser,
            };
        },
        deleteUser() {
            this.saveUser(true);
        },
        saveUser(deleted = false) {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            const { newUserObject, editUserObject, isNewUser } = this;
            const { schoolId, schoolTermId } = v.user.school;

            const params = {
                url: { schoolId, schoolTermId },
                body: { user: isNewUser ? newUserObject : editUserObject },
                query: null,
            };

            params.body.user.deleted = deleted;

            network.users.createNewUser(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                v.$store.commit(Types.mutations.UPSERT_USER, { u: res.user });
                // v.showAlert(isNewUser ? `${params.body.user.schoolEmail} created` : `${params.body.user.schoolEmail} edited`, null, 'success');
                v.$router.push({ name: 'ManageUsers' });
            });
        },
    },
});
</script>

<style scoped>

.sg-bordered-row {
    border: solid lightgrey 1px;
    border-radius: 10px;
    padding: 10px;
}
.sg-bordered-row.caution {
    border: solid orange 1px;
}
.sg-bordered-row.success {
    border: solid green 1px;
}

span.sg-border-label {
    margin-top: -20px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
    font-weight: 500;
    font-size: 1.1em;
}
span.sg-border-label.caution {
    color: orange;
}
span.sg-border-label.success {
    color: green;
}
.is-invalid-warn {
    border-color: #ffb822 !important;
}
</style>
